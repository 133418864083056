/**************************************************
 * Nombre:       BarraCell
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Drawer, Grid, IconButton, Typography} from "@mui/material";
import logo from "../../Recursos/logo_blanco.svg";
import {Close, Menu} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {SECCIONES} from "../../Constantes";
import {theme} from "../../Tema";
import {obtenerSeccionUrl} from "../../Utilidades/obtenerSeccionUrl";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";


const BarraCell = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const [isDetalle, setIsDetalle] = useState(false)
    const {BotonCarrito} = useCarritoCompras()


    useEffect(() => {

        if (location) {
            setIsDetalle(obtenerSeccionUrl({
                path: location.pathname,
                index: 1
            }) === 'Detalle' || obtenerSeccionUrl({path: location.pathname, index: 1}) === 'Payments')
        }

    }, [location]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{marginBottom: -16}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        backgroundColor: isDetalle ? '#000' : '#FFFFFF30',
                        border: 1,
                        borderColor: '#FFFFFF40',
                        px: 3,
                        py: 2,
                        backdropFilter: 'blur(6px)',
                        borderBottomRightRadius: 30
                    }}
                >

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start', pl: 1}}>
                        <img src={logo} width={120} height={'auto'} style={{cursor: 'pointer'}}
                             onClick={() => navigate('/Home')}
                        />
                    </Grid>

                    <Grid item container lg={12} sm={4} xs={2} sx={{justifyContent: 'flex-start'}}>

                    </Grid>

                    <Grid item container lg={12} sm={1} xs={2} sx={{justifyContent: 'flex-end'}}>
                        <BotonCarrito/>
                    </Grid>


                    <Grid item container lg={12} sm={1} xs={2} sx={{justifyContent: 'flex-end'}}>
                        <IconButton
                            onClick={() => setOpen(true)}
                        >
                            <Menu color={'secondary'} sx={{height: 30, width: 30}}/>
                        </IconButton>
                    </Grid>


                </Grid>
            </Grid>

            <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 250, p: 2}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <IconButton
                            onClick={() => setOpen(false)}
                        >
                            <Close color={'secondary'}/>
                        </IconButton>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            {SECCIONES.map((item, index) => {
                                return (
                                    <Grid key={`sd${index}`} item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'center', marginTop: 3}}>
                                        <ButtonBase
                                            onClick={() => {
                                                navigate('/' + item)
                                                setTimeout(() => {
                                                    setOpen(false)
                                                }, 500)

                                            }
                                            }

                                        >
                                            <Typography
                                                sx={{
                                                    color: theme.palette.secondary.main,
                                                    fontSize: 24,
                                                    fontWeight: 500
                                                }}>{item}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', position: 'fixed', bottom: '6%', left: '20%'}}>
                        <img src={logo} width={150} height={'auto'}/>
                    </Grid>

                </Grid>
            </Drawer>

        </Grid>

    )

}
export default BarraCell    